
/* PAGE */
._container {
  position: absolute;
  display: flex;
  height: auto;
  width: 100%;
  left: 0px;
  top: 0px;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-12dp);
}
@media screen and (max-width: 800px) {
  ._container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
._container .bg_shape {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  overflow: hidden;
}
._container .bg_shape .shape {
  position: absolute;
  height: 120%;
  width: 130%;
  left: -15%;
  top: -10%;
  fill: var(--color-background-24dp);
}
._container .bg_shape .shape:first-child:not(:last-child) {
  fill: var(--color-background-0dp);
  transform: rotate(6deg) scale(1.01) translateY(-5px);
  filter: blur(2px);
  opacity: .08;
  /*display: none;*/
}
@media screen and (max-width: 500px) {
  ._container .bg_shape .shape {
    height: auto;
    width: 170%;
    left: -30%;
    top: 12%;
    transform: rotate(10deg);
  }
  ._container .bg_shape .shape:first-child:not(:last-child) {
    display: none;
  }
}
._container .sign_in_up {
  position: fixed;
  right: 60px;
  top: 40px;
  cursor: pointer;
  border-radius: 12px;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  ._container .sign_in_up {
    position: absolute;
  }
}
._container .sign_in_up /deep/ span:last-child {
  position: relative;
  color: var(--color-brand);
  margin-left: 5px;
  transition: .1s;
  font-weight: bold;
}
._container .sign_in_up /deep/ span:last-child:before {
  position: absolute;
  content: ' ';
  height: 1px;
  width: 100%;
  left: 0px;
  top: 100%;
  background-color: currentColor;
  transform-origin: left;
  transform: scaleX(0);
  transition: inherit;
}
._container .sign_in_up:hover /deep/ span:last-child:before {
  transform: scaleX(1);
}
@media screen and (max-width: 720px) {
  ._container .sign_in_up {
    right: 40px;
  }
}
@media screen and (max-width: 520px) {
  ._container .sign_in_up {
    top: 30px;
    right: 30px;
    padding: 10px;
    background-color: var(--color-background-24dp);
    border: 1px solid var(--color-text-light);
  }
  ._container .sign_in_up /deep/ span:first-child,
  ._container .sign_in_up /deep/ span:last-child:before {
    display: none;
  }
}
._container ._content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  padding: 30px;
  min-width: 460px;
  border-radius: 10px;
  max-height: 100%;
}
@media screen and (max-width: 460px) {
  ._container ._content {
    min-width: 100px;
  }
}
._container ._content .title {
  font-size: 30px;
  padding-right: 3%;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}
@media screen and (max-width: 700px) {
  ._container ._content .title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 460px) {
  ._container ._content .title {
    text-align: left;
  }
}
._container ._content .illustration {
  position: relative;
  display: flex;
  height: 160px;
  width: 100%;
  justify-content: center;
}
._container ._content .illustration /deep/ .decor {
  width: 330px;
}
